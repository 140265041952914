import { IGetChildrenPayload } from '@/store/actions'
import { IMenuItem } from '@/store/state'
import axios from 'axios'

const headers = {
	'X-Requested-With': 'XMLHttpRequest',
	'Accept': 'application/json',
}


export const getMenu = (getMenuURL: string): Promise<IMenuItem[]> => {
    const url = getMenuURL;
    return axios.get(url, { headers })
        .then((response:any) => {

            return response.data
        })
        .catch((searchError:any) => {
            console.error({
                searchError
            })
            return {} as IMenuItem[]
        })
}

export const getMenuItems = (searchURL: string, params: IGetChildrenPayload): Promise<IMenuItem[]> => {
    //Used instead of query-string
    const queryString = Object.entries(params)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');
    const url = `${searchURL}&${queryString}`;
    return axios.get(url, { headers })
        .then((response:any) => {
            return response.data
        })
        .catch((searchError:any) => {
            console.error({
                searchError
            })
            return {} as IMenuItem[]
        })
}

export const getSuggestions = (searchURL: string, param: string): Promise<string[]> => {
    const url = `${searchURL}&query=${encodeURIComponent(param)}`;
    return axios.get(url, { headers })
        .then((response:any) => {
            return response.data
        })
        .catch((searchError:any) => {
            console.error({
                searchError
            })
            return {} as string[]
        })
}