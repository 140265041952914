
import Vue from 'vue'
import { IMenuItem } from '../store/state'
import { toggleExpanded } from '../store/actions'

// Interfaces for component typechecking!
interface MenuItemData {

}

interface MenuItemMethods {
    toggleExpanded(item: IMenuItem): void
}

interface MenuItemComputed {

}

interface MenuItemProps {
    item: IMenuItem
}

export default Vue.extend<MenuItemData, MenuItemMethods, MenuItemComputed, MenuItemProps>({
	name: 'MenuItem',
    props: {
        item: {}
    },
	methods: {
        toggleExpanded(item) {
            this.$store.dispatch(toggleExpanded(item))
        }
	},
	computed: {

	},
	components: {

	}
})
