
import Vue from 'vue'
import { IContact } from '../store/state'

// Interfaces for component typechecking!
interface ContactData {

}

interface ContactMethods {
	
}

interface ContactComputed {
	contact: IContact
}

interface ContactProps {

}

export default Vue.extend<ContactData, ContactMethods, ContactComputed, ContactProps>({
	name: 'Contact',
	methods: {
		
	},
	computed: {
		contact() {
			return this.$store.getters.contact
		}
	},
	components: {
		
	}
})
