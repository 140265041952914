
import Vue from 'vue'
import { getSuggestions, removeSuggestions } from '../store/actions'
import { ISuggestion } from '../store/state'

// Interfaces for component typechecking!
interface SearchData {
    query: string
}

interface SearchMethods {
	getSuggestions(): void
    searchSuggestion(e: Event, suggestion: ISuggestion): void
    toSuggestions(e: Event): void
    nextSuggestion(e: Event, index: number): void
    previousSuggestion(e: Event, index: number): void
    closeSuggestions(focusInput: boolean): void
}

interface SearchComputed {
    searchPageUri: string
    suggestions: string[]
}

interface SearchProps {

}

export default Vue.extend<SearchData, SearchMethods, SearchComputed, SearchProps>({
	name: 'Search',
    data(){
        return {
            query: '',
        }
    },
	methods: {
        getSuggestions() {
            if(this.query.length > 2) {
                this.$store.dispatch(getSuggestions(this.query))
            } else if(this.suggestions.length > 0) {
                this.$store.dispatch(removeSuggestions(undefined))
            }
        },
        searchSuggestion(e, suggestion) {
            e.preventDefault();
            (this.$refs.input as HTMLInputElement).value = suggestion.query;
            (this.$refs.form as HTMLFormElement).submit()
        },
        toSuggestions(e) {
            e.preventDefault();
            if(this.suggestions.length > 0) {
                const suggestions = this.$refs.suggestion as Array<HTMLAnchorElement>;
                suggestions[0].focus();
            }
        },
        nextSuggestion(e, index) {
            e.preventDefault();
            if((index + 1) < this.suggestions.length) {
                const suggestions = this.$refs.suggestion as Array<HTMLAnchorElement>;
                suggestions[index + 1].focus();
            }
            
        },
        previousSuggestion(e, index) {
            e.preventDefault();
            if(index > 0) {
                const suggestions = this.$refs.suggestion as Array<HTMLAnchorElement>;
                suggestions[index - 1].focus();
            } else {
                const input = this.$refs.input as HTMLInputElement;
                input.focus();
            }
        },
        closeSuggestions(focusInput) {
            this.$store.dispatch(removeSuggestions(undefined))
            if(focusInput) {
                const input = this.$refs.input as HTMLInputElement;
                input.focus();
            }
        },
	},
	computed: {
        searchPageUri() {
            return this.$store.getters.searchPageUri
        },
        suggestions() {
            return this.$store.getters.suggestions
        },
	},
    mounted() {
        (this.$refs.input as HTMLInputElement).focus()
    },
	components: {
		
	}
})
