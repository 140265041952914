import { IMenuItem, IState, IView } from "./state";
import { ActionTree, Store, ActionContext } from 'vuex';
import * as api from '../api'

type PayloadType = 'initApp' | 'setState' | 'getChildren' | 'getMenu' | 'toggleExpanded' | 'getSuggestions' |
'removeSuggestions' | 'toggleView'

export type ActionPayload<ActionData> = {
	type: PayloadType,
	payload: ActionData
}

export interface IGetChildrenPayload {
	id: string
	level: number
}
export interface IAddMenuItemsPayload {
	menuItem: IMenuItem,
	items: IMenuItem[]
}

type ActionHandler<ActionData> = (this: Store<IState>, injectee: ActionContext<IState, IState>, payload: ActionPayload<ActionData>) => any;
type ActionCreator<ActionData> = (data: ActionData) => ActionPayload<ActionData>;


// Action creators
export const initApp: ActionCreator<IState> = (state) => ({
	type: 'initApp',
	payload: state
})
export const getChildren: ActionCreator<IGetChildrenPayload> = payload => ({
	type: 'getChildren',
	payload: payload
})
export const getSuggestions: ActionCreator<string> = payload => ({
	type: 'getSuggestions',
	payload: payload
})
export const getMenu: ActionCreator<undefined> = (param) => ({
	type: 'getMenu',
	payload: param
})
export const toggleExpanded: ActionCreator<IMenuItem> = menuItem => ({
	type: 'toggleExpanded',
	payload: menuItem
})
export const removeSuggestions: ActionCreator<undefined> = () => ({
	type: 'removeSuggestions',
	payload: undefined
})
export const toggleView: ActionCreator<IView> = payload => ({
	type: 'toggleView',
	payload: payload
})

// Action handlers
const initAppAction: ActionHandler<IState> = ({ commit }, { payload }) => {
	commit({
		type: 'setState',
		payload
	})
}
const getChildrenAction: ActionHandler<IMenuItem> = ({ commit, state }, { payload }) => {
	api.getMenuItems(state.endpoints.getChildren, {id: payload.id, level: payload.level})
		.then(res => {
			commit({
				type: 'addMenuItems',
				payload: {menuItem: payload, items: res}
			})
		})
}
const getSuggestionsAction: ActionHandler<string> = ({ commit, state }, { payload }) => {
	const getSuggestionsURL = state.endpoints.getSuggestions;
	api.getSuggestions(getSuggestionsURL, payload)
		.then(res => {
			commit({
				type: 'setSuggestions',
				payload: res
			})
		})
}
const getMenuAction: ActionHandler<undefined> = ({ commit, state }, { payload }) => {
	api.getMenu(state.endpoints.getMenu)
		.then(res => {
			commit({
				type: 'setMenu',
				payload: res
			})
		})
}
const toggleExpandedAction: ActionHandler<IMenuItem> = ({ commit, dispatch, state }, { payload }) => {
	commit({
		type: 'toggleExpanded',
		payload: payload
	})

	if(payload.children.length < 1) {
		dispatch(getChildren(payload))
	}
}
const removeSuggestionsAction: ActionHandler<string> = ({ commit, state }, { payload }) => {
	commit({
		type: 'removeSuggestions',
		payload: payload
	})
}
const toggleViewAction: ActionHandler<IView> = ({ commit, dispatch }, { payload }) => {
	if(payload.name === 'menu') {
		dispatch(getMenu(undefined))
	}
	commit({
		type: 'toggleView',
		payload
	})
}

const actions: ActionTree<IState, IState> = {
	initApp: initAppAction,
	getChildren: getChildrenAction,
	getMenu: getMenuAction,
	toggleExpanded: toggleExpandedAction,
	getSuggestions: getSuggestionsAction,
	removeSuggestions: removeSuggestionsAction,
	toggleView: toggleViewAction,
}

export default actions;
