import { MutationTree, Mutation } from 'vuex';
import { IState, IMenuItem, ISuggestion, IView } from './state';
import { ActionPayload, IAddMenuItemsPayload } from './actions';

type MutationHandler<PayloadType> = (state: IState, payload: ActionPayload<PayloadType>) => any;

const setStateMutation: MutationHandler<IState> = (state, { payload }) => {
	Object.assign(state, payload);
}
const toggleMenuMutation: MutationHandler<boolean> = (state, { payload }) => {
	Object.assign(state.menu, { 
		isOpen: payload
	});
}
const setMenuMutation: MutationHandler<IMenuItem[]> = (state, { payload }) => {
	state.menu = payload
}
const addMenuItemsMutation: MutationHandler<IAddMenuItemsPayload> = (state, { payload }) => {
	if(payload.menuItem) {
		Object.assign(payload.menuItem, { children: payload.items})
	}
}
const toggleExpandedMutation: MutationHandler<IMenuItem> = (state, { payload }) => {
	Object.assign(payload, {isExpanded: !payload.isExpanded})
}
const setSuggestionsMutation: MutationHandler<ISuggestion[]> = (state, { payload }) => {
	const key = "query";
	const uniqued = [...new Map(payload.map(item => [item[key], item])).values()];
	Object.assign(state, {suggestions: uniqued})
}
const removeSuggestionsMutation: MutationHandler<IMenuItem> = (state, { payload }) => {
	Object.assign(state, {suggestions: []})
}
const toggleViewMutation: MutationHandler<IView> = (state, { payload }) => {
	state.views.forEach(view => {
		if(view.name === payload.name) {
			view.open = !view.open;
		} else {
			view.open = false;
		}
	})
}

const mutations: MutationTree<IState> = {
	setState: setStateMutation,
	toggleMenu: toggleMenuMutation,
	setMenu: setMenuMutation,
	addMenuItems: addMenuItemsMutation,
	toggleExpanded: toggleExpandedMutation,
	setSuggestions: setSuggestionsMutation,
	removeSuggestions: removeSuggestionsMutation,
	toggleView: toggleViewMutation
}

export default mutations
