export interface IState {
	menu: IMenuItem[],
	endpoints: {
		getMenu: string,
		getChildren: string,
		searchPageUri: string
		getSuggestions: string,
	},
	views: IView[]
	suggestions: ISuggestion[]
	logoUri: string
	contact: IContact
	eServices: string
	bulletinBoard: string
	easyRead: string
	signLanguage: string
	otherLanguages: string,
	isStartPage: boolean
	isMainStartPage: boolean
	startPageUri: string
	mainStartPageUri: string
	isSubWeb: boolean
	subWebName: string
	colors: IColors
}

export interface IColors {
	primary: string
	secondary: string
	menuLevel2: string
	menuLevel3: string
	menuLevel4: string
	menuLevel5: string
}

export interface ILink {
	text: string
	linkUri: string
}

export interface IContact {
	mail: string
	phone: string
	links: ILink[]
	rightColumnHtml: string
}

export interface IView {
	name: string
	open: boolean
	value: string
}

export interface ISuggestion {
	query: string
	html: string
}

export interface IMenuItem {
	name: string
	uri: string
	id: string
	isCurrent: boolean
	isExpanded: boolean
	hasChildren: boolean
	level: number
	children: IMenuItem[]
}

const deafultState: IState = {
	menu: [],
	endpoints: {
		getMenu: '',
		getChildren: '',
		searchPageUri: '',
		getSuggestions: '',
	},
	views: [
		{
			name: 'search',
			open: false,
			value: 'Sök'
		},
		{
			name: 'contact',
			open: false,
			value: 'Kontakt'
		},
		{
			name: 'menu',
			open: false,
			value: 'Meny'
		},
	],
	suggestions: [],
	logoUri: '',
	contact: {
		mail: '',
		phone: '',
		links: [],
		rightColumnHtml: ''
	},
	eServices: '',
	bulletinBoard: '',
	easyRead: '',
	signLanguage: '',
	otherLanguages: '',
	isStartPage: false,
	isMainStartPage: false,
	startPageUri: '',
	mainStartPageUri: '',
	isSubWeb: false,
	subWebName: '',
	colors: {
		primary:'',
		secondary:'',
		menuLevel2:'',
		menuLevel3:'',
		menuLevel4:'',
		menuLevel5:''
	}
}

export default deafultState;
